.App .welcome .callToAction{
    width: 1080px;
    position: absolute;
    top: 190px;
}

.App .welcome .atleticam{
    width: 1100px;
    position: absolute;
    top: 820px;
    left: -10px;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline-offset: -10px;
}

.App .welcome .buttons{
    width: 1080px;
    position: absolute;
    top: 1000px;
    font-size: 80px;
}

.App .welcome .buttonRight{
    position: absolute;
    top: 0px;
    right: 50px;
    cursor: pointer;
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    z-index: 900;
}

.App .welcome .buttonLeft{
    position: absolute;
    top: 20px;
    left: 50px;
    cursor: pointer;
    width: 40%;
    margin: 0 auto;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    z-index: 900;
}

.App .welcome .buttonSolid{
    cursor: pointer;
    width: 410px;
    padding: 20px;
    padding-top: 22px;
    font-size: 55px;
    padding-bottom: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
    border-radius: 7px;
}

.App .welcome .buttonOutline{
    cursor: pointer;
    width: 410px;
    padding: 20px;
    font-size: 55px;
    font-weight: bold;
    color: white;
    text-align: center;
    border-radius: 7px;
}

.App .welcome .textSolid{
    width: 450px;
    margin: 0 auto;
    font-size: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.App .welcome .textOutline{
    width: 450px;
    margin: 0 auto;
    font-size: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.App .welcome .texts{
    width: 1080px;
    position: absolute;
    top: 1320px;
    font-size: 75px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App .welcome .textEs{
    width: 45%;    
}

.App .welcome .textEn{
    text-align: right;
    width: 45%;
}

.App .welcome .verticalDivider{
    height: 385px;
    width: 10px;
    display: block;
    border-radius: 10px;
    margin-left: 40px;
    margin-right: 40px;
}

