.App .players{
    border-radius: 20px;
    padding: 40px;
    position: absolute;
    top: 190px;
    width: 900px;
    height: 1130px;
    left: 50%;
    transform: translateX(-50%);
}

.App .players h1{
    position: absolute;
    margin: 0 auto;
    width: 90%;
    font-size: 60px;
    text-align: center;
    z-index: 999;
}

.App .players .continuar{
    cursor: pointer;
    position: absolute;
    top: 89%;
    left: 50%;
    width: 42%;
    margin: 0 auto;
    padding: 15px;
    font-size: 45px;
    color: white;
    text-align: center;
    border-radius: 7px;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.App .players .carrousel{
    position: absolute;
    width: 980px;
    left: 0%;
    top: 25%
}

.App .players .selected{
    position: absolute;
    display: block;
    top: 57%;
    left: 50%;
    width: 563px;
    height: 916px;
    border-radius: 56px;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.App .players .playerName{
    position: absolute;
    color: white;
    font-size: 40px;
    font-weight: bold;
    padding: 20px;
    border-radius: 7px;
    width: 400px;
    text-align: center;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 900;
}

.App .players .arrowRightButton{
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100px;    
    height: 100px;
    left: 79%;
    z-index: 1000;
}

.App .players .arrowLeftButton{
    position: absolute;
    transform: translate(-50%, -50%) rotate(180deg);
    top: 50%;
    left: 21%;
    width: 100px;    
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.App .players .poses_animation{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    transition: opacity 0.1s;
    justify-content: center;
    align-items: center;
    z-index: 760;

}

.App .players svg{
    display: block;
    position: relative;
    top: 30%;
    left: 30%;    
}

.App .players .fadeL{
    display: block;
    position: absolute;    
    transform: translate(-50%, -50%);
    top: 57%;
    left: 19%;
    width: 200px;
    height: 916px;
    border-radius: 56px;
    z-index: 998;
} 

.App .players .fadeR{
    display: block;
    position: absolute;    
    transform: translate(-50%, -50%);
    top: 57%;
    left: 72%;
    width: 200px;
    height: 916px;
    border-radius: 56px;
    z-index: 998;
} 