.App .photo{
    border-radius: 20px;
    padding: 40px;
    position: absolute;
    top: 190px;
    width: 900px;
    height: 1230px;
    left: 50%;
    transform: translateX(-50%);
}

.App .photo .continuar{
    cursor: pointer;
    position: absolute;
    width: 30%;
    padding: 20px;
    font-size: 45px;
    color: white;
    font-weight: bold;
    text-align: center;
    left: 80%;
    top:50%;
    border-radius: 7px;
    transform: translate(-50%, -50%);
    z-index: 900;
}

.App .photo .repeat{
    cursor: pointer;
    position: absolute;
    width: 30%;
    font-weight: bold;
    margin: 0 auto;
    left: 80%;
    top:60%;
    padding: 17px;
    font-size: 45px;
    color: white;
    text-align: center;
    transform: translate(-50%, -50%);
    border-radius: 7px;
    z-index: 900;
}

.App .photo .photoHolder{
    width: 1080px;
    height: 1920px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    z-index: 800;
    transform: translate(-50%, -50%) scale(0.5);
    top: 57%;
    left: 32%;  
    border-radius: 50px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.3);
  }

.App .photo h1{
    position: absolute;
    margin: 0 auto;
    width: 90%;
    font-size: 60px;
    text-align: center;
    z-index: 999;
} 

