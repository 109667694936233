.exo-Regular {
  font-family: "Exo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body{
  font-family: "Exo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
  background-color: #000;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

img {
  pointer-events: none;
}

.App{
  position: absolute;
  overflow: hidden;
  background-color: #393656;
  width: 1080px;
  height: 1920px;  
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.App .ready{
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.App .standby{
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.App .smile{
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.App .timer{
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}


.App .boton{
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 80%;
  left: 50%;
  cursor: pointer;
  opacity: 1;
  transition: all 1s ease-out;
}


.fade-in-image { animation: fadeIn 1s; }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.App .boton:active {
  transform: translate(-50%, -47%);
}

.App .transitionUp {
	position: absolute;
  transform: translate(100%, -130%) scale(4);
  top: -1000px;
  left: -1740px;  
  opacity: 1;
  pointer-events: none;
  z-index: 1050;
}

.App .transitionDown {
	position: absolute;
  transform: translate(0%, 130%) scale(-4); 
  top: 3840px;
  left: 0px;
  z-index: 1050;
  opacity: 1;
  pointer-events: none;
}
