.App .footer{
    height: 190px;
    width: 1100px;
    text-align: center;
    position: absolute;
    z-index: 99;
    bottom: -30px;
    left: -10px;
    display: flex;
    justify-content: center;
    z-index: 1000;
    align-items: center;
}
  
.App .footer .logo{
    width: 1100px;
    outline-offset: -10px;
}
  