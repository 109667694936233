.App .camera .fadeOverlay{
    position: absolute;    
    width: 1080px;
    height: 1920px;  
    z-index: 98;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 1;
    transition: all 1s ease-out;
    z-index: 999;
}

.App .camera .robot{
  position: absolute;
  z-index: 98;
  transform: translate(-50%, -50%);
  top: 60%;
  left: 32%;
  opacity: 1;
  transition: all 1s ease-out;
}

.App .camera .camara{
  position: absolute;
  transform: translate(-50%, -50%) scale(0.8) rotate(90deg) ;
  top: 50%;
  left: 50%;
  width: 1920px;
  height: 1080px;
  object-fit: cover;
  z-index: 9;
}

.App .camera .video{
  position: absolute;
  transform: translate(-50%, -50%) scale(0.8);
  top: 50%;
  left: 50%;
  width: 1080px;
  height: 1920px;  
  z-index: 1030;
  opacity: 0;
  transition: all 1s ease-out;
}

.App .camera .canvas{  
  position: absolute;
  top: 0%;
  left: -100%;
  width: 1080px;
  height: 1920px;
}

.App .camera .watermark{  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 860px;
  z-index: 900;
}

.App .camera .position_center{  
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 860px;
  z-index: 900;
}

.App .camera .position_left{  
  position: absolute;
  top: 55%;
  left: 60%;
  transform: translate(-50%, -50%) scale(1);
  width: 860px;
  z-index: 900;
}

.App .camera .position_right{  
  position: absolute;
  top: 55%;
  left: 40%;
  transform: translate(-50%, -50%) scale(1);
  width: 860px;
  z-index: 900;
}


.App .camera .CrossfadeImage{
  position: absolute !important;
  width: 1080px;
  height: 1920px;  
  z-index: 97;
  transform: translate(-50%, -50%) scale(0.8);
  top: 50%;
  left: 50%;
  z-index: 1900;
}

.App .camera h1{
  position: absolute;
  top: 18%;
  left: 50%;
  margin: 0 auto;
  width: 70%;
  font-size: 60px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 900;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.App .camera .whiteScreen{
  position: absolute;
  background-color: white;
  width: 1080px;
  height: 1920px;  
  z-index: 999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 2000;
}

.App .camera .FadeUp{
  position: absolute;
  top: 19.5%;
  left: 50%;
  margin: 0 auto;
  width: 80%;
  transform: translate(-50%, -50%);
  z-index: 899;
}


