.App .header{
    height: 190px;
    width: 1080px;
    text-align: center;
    position: absolute;
    z-index: 99;
    top: 0%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}
  
.App .header .logo{
    height: 130px;
}
  