.App .data{
    border-radius: 20px;
    padding: 40px;
    position: absolute;
    top: 190px;
    width: 900px;
    height: 1150px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 34px;
}

.App .data h1{
    margin: 0 auto;
    width: 80%;
    font-size: 60px;
    text-align: center;
}

.App .data .continuar{
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    width: 88%;
    margin: 0 auto;
    padding: 20px;
    font-weight: bold;
    font-size: 55px;
    color: white;
    text-align: center;
    border-radius: 7px;
}

.App .data .simple-keyboard{
    position: absolute;
    z-index: 101;
    width: 800px;
    transform: translate(-50%, -50%) scale(1.225);
    font-size: 0.5em;
    top: 400px;
    left: 50%;
}

.App .data .keyboardBox{
    position: absolute;
    width: 100%;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.App .data .formulario{
    transform: scale(0.8);
    width: 900px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.App .data .formgroup{
    width: 43%;    
    margin-right: 55px;
    margin-bottom: 10px;
}

.App .data input{
    font-family: "Exo", sans-serif;
    font-size: 30px;
    padding: 15px;
    border: none;
    width: 100%;
    border-radius: 7px;
    transition: opacity 0.5s ease-out;
}

.App .data label{
    display: block;
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
    border: none;
    width: 100%;
    border-radius: 20px;
    transition: opacity 0.5s ease-out;
}

input[type=text]:focus {
    border: none;
    outline: none;
}

.required{
    border: 3px solid red;
}