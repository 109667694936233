.App .legal{
    border-radius: 20px;
    padding: 40px;
    position: absolute;
    top: 190px;
    width: 900px;
    height: 980px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 34px;
}

.App .legal h1{
    margin: 0 auto;
    width: 80%;
    font-size: 60px;
    text-align: center;
}

.App .legal .continuar{
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    width: 88%;
    margin: 0 auto;
    font-weight: bold;
    padding: 20px;
    font-size: 55px;
    color: white;
    text-align: center;
    border-radius: 7px;
}

.App .legal .formulario{
    width: 900px;
    font-size: 35px;
    margin-top: 50px;
}


.App .legal a{
    cursor: pointer;    
    text-decoration: underline;
    font-weight: bold;
}

.App .legal input{
    padding: 120px;
}

.App .legal .formgroup{
    margin-bottom: 70px;
}

.App .legal .formgroup2{
    margin-left: 50px;
}


.App .legal .container {
    display: block;
    position: relative;
    padding-left: 70px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 35px;
    border-radius: 7px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 50px;
  }
  
  .App .legal  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .App .legal .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    border-radius: 7px;
    background-color: #FFF;
  }
  
  /* On mouse-over, add a grey background color */
  .App .legal .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .App .legal .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .App .legal .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .App .legal .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .App .legal  .container .checkmark:after {
    left: 17px;
    top: 10px;
    width: 10px;
    height: 20px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .App .legal .legalWindow{
    background-color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 900px;
    padding: 50px;
    height: 1000px;
    overflow: hidden;
    font-size: 26px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .App .legal .legalWindow .content{
    width: 860px;
    height: 800px;
    overflow-y: scroll;
    border: 1px solid #efefef;
    border-radius: 20px;
    padding: 20px;
  }

  .App .legal .legalWindow .close{
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    width: 88%;
    margin: 0 auto;
    font-weight: bold;
    padding: 20px;
    font-size: 55px;
    color: white;
    text-align: center;
    border-radius: 7px;
}